import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Funnel = () => {
  return (
    <article className="group relative mx-auto max-w-md cursor-pointer bg-white shadow-lg">
      <div className="overflow-hidden">
        <StaticImage
          className="h-auto w-full transform duration-300 ease-out hover:scale-110 hover:ease-in"
          src="../../assets/images/funnel.jpeg"
          alt="Payment Gateway"
          as="div"
          layout="fullWidth"
          placeholder="tracedSVG"
        />
      </div>
      <div className="my-auto p-2 pb-5 ">
        <h2 className="min-h-18 mt-8 text-center text-3xl font-bold">
          Funnels Building
        </h2>
        <p className="p-4 text-center text-sm capitalize leading-relaxed md:text-lg ">
          Funnel development, funnel clone, custom script writting and api
          integration (Clickfunnels, Kajabi, gohighlevel, leadpage, wix and
          others).
        </p>
      </div>
    </article>
  )
}

export default Funnel
