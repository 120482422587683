import React, { createRef } from "react"
import Software from "./services/Software"
import Mobileapp from "./services/Mobileapp"
import Website from "./services/Website"
import Funnel from "./services/Funnel"
import Others from "./services/Others"
import Payment from "./services/Payment"
import Zap from "./services/Zapier"
import Autoresponder from "./services/Autoresponder"
import Funnelclone from "./services/Funnelclone"

const ServiceSection = () => {
  const messagesEndRef = createRef<HTMLInputElement>()
  function scrollToBottom() {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }
  return (
    <section className="container mx-auto antialiased">
      <section className="grid grid-cols-1">
        <Funnelclone />
      </section>
      <section
        onClick={scrollToBottom}
        className="grid grid-cols-1 gap-10 p-5 md:p-0 lg:grid-cols-2 2xl:grid-cols-3"
      >
        <Software />
        <Mobileapp />
        <Website />
        <Funnel />
        <Payment />
        <Autoresponder />
        <Zap />
        <Others />
      </section>
      <section
        ref={messagesEndRef}
        className="container mx-auto p-5  antialiased"
      >
        <div className="mt-20 w-full text-center">
          <h1 className="text-center text-4xl font-bold text-black md:mt-5">
            You're Just One Click Away...
          </h1>
          <p className="mt-2 text-center text-xl">
            So you're here finally, i hope after reading all the information you
            made your mind. Somehow if you're still confused, just reach out me
            and make a 30mins free consultation.
          </p>
          <a
            className="mt-5 inline-block rounded-full border-2 border-none bg-button py-2 px-10 text-lg text-white transition duration-1000 hover:bg-indigo-500"
            href="/meet-with-md"
            target="_blank"
          >
            Schedule A Call
          </a>
        </div>
      </section>
    </section>
  )
}

export default ServiceSection
