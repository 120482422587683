import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Software = () => {
  return (
    <article className="group relative mx-auto max-w-md cursor-pointer bg-white shadow-lg">
      <div className="overflow-hidden bg-button">
        <StaticImage
          className="h-auto w-full transform duration-300 ease-out hover:scale-110 hover:ease-in"
          src="../../assets/images/software.png"
          alt="Mobile App Development"
          as="div"
          layout="fullWidth"
          placeholder="tracedSVG"
        />
      </div>
      <div className="my-auto p-2 pb-5 ">
        <h2 className="min-h-18 mt-8 text-center text-3xl font-bold">
          Web Software
        </h2>
        <p className="p-4 text-center text-sm capitalize leading-relaxed md:text-lg ">
          As Entrepreneur, I developed my own custom web based software using
          react, php & python.
        </p>
      </div>
    </article>
  )
}

export default Software
