import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Funnelclone = () => {
  return (
    <section className="container mx-auto mb-20 transform duration-500">
      <a
        href="https://funnelclone.com"
        target="_blank"
        className="mx-auto flex flex-wrap bg-[#7730a2] text-white shadow-xl md:flex-nowrap "
      >
        <div className="h-auto w-full bg-white p-[20px] md:w-[50%]">
          <StaticImage
            src="../../assets/images/funnelclone.png"
            alt="MD Furkanul Islam"
            as="div"
            placeholder="dominantColor"
            className="h-auto w-full"
            layout="fullWidth"
          />
          <h1 className="text-2xl font-semibold text-[#7730a2]">
            Funnel Clone
          </h1>
        </div>

        <div className="my-auto  p-10">
          <p className="text-2xl font-semibold capitalize">
            Stop Spending Money To Copy Competitors Funnels. Convert any page
            into a funnel with a reasonable price.
          </p>
          <p className="mt-5 inline-block rounded-full border-2 border-none bg-button py-2 px-10 text-lg text-white transition duration-1000 ease-out hover:bg-indigo-500 hover:ease-in">
            More Details
          </p>
        </div>
      </a>
    </section>
  )
}

export default Funnelclone
