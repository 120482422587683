import React from "react"
import Layout from "../components/Layout"
import { SEO } from "../components/Seo"
import ServiceSection from "../components/ServiceSection"

const Services = () => {
  return (
    <>
      <Layout>
        <main className="container mx-auto mb-5 flex flex-col pb-5 md:my-10 md:max-w-5xl md:pb-20">
          <div className="w-full py-10 text-center">
            <h1 className="text-4xl font-bold text-black ">
              Here's What I offer ...
            </h1>
            <p className="mt-2 text-center text-xl">
              Checkout the below listed services i usually providing. <br></br>
              If you're looking for something else, don't hesitate to contact
              with me.
            </p>
          </div>
          <div className="w-full py-10 text-center">
            <ServiceSection />
          </div>
        </main>
      </Layout>
    </>
  )
}

export default Services

export const Head = () => (
  <SEO
    title="Services"
    description="Here's What I offer ...
    Checkout the below listed services i usually providing.
    If you're looking for something else, don't hesitate to contact with me."
    pathname="services"
    children={undefined}
  ></SEO>
)
